import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { ReCaptchaV2 } from 'grecaptcha';
import { isPlatformBrowser } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY } from '@libs/captcha/tokens';
import { ReCaptchaMissedToken, ReCaptchaUndefined } from '@libs/captcha/errors';
import { WINDOW } from '@awarenow/profi-ui-core';
import { ScriptLoaderService } from '@libs/services/script-loader/script-loader.service';

@Injectable()
export class ReCaptchaV3Service {
  private grecaptcha?: ReCaptchaV2.ReCaptcha;

  get isReady(): boolean {
    return !!this.grecaptcha;
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(RECAPTCHA_V3_SITE_KEY) private siteToken: string,
    @Inject(WINDOW) private windowService: Window,
    private scriptLoader: ScriptLoaderService
  ) {
    if (this.grecaptcha === undefined) {
      from(
        this.scriptLoader.load({ src: `//google.com/recaptcha/enterprise.js?render=${this.siteToken}`, async: true })
      )
        .pipe()
        .subscribe(() => {
          if (this.windowService.hasOwnProperty('grecaptcha')) {
            this.grecaptcha = grecaptcha;
          }
        });
    }

    if (isPlatformBrowser(platformId)) {
      if (this.windowService.hasOwnProperty('grecaptcha')) {
        this.grecaptcha = grecaptcha;
      }
    }
  }

  execute(action: string): Observable<string> {
    if (!this.grecaptcha) {
      console.error(new ReCaptchaUndefined());

      return EMPTY;
    }

    return new Observable(subscriber => {
      this.grecaptcha.enterprise.ready(() => {
        from(this.grecaptcha.enterprise.execute(this.siteToken, { action }))
          .pipe(
            take(1),
            map(token => {
              if (!token) {
                throw new ReCaptchaMissedToken();
              }

              return token;
            })
          )
          .subscribe((token: string) => {
            subscriber.next(token);
          });
      });
    });
  }
}
