import { APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { ScriptLoaderService } from '@libs/services/script-loader/script-loader.service';
import { WINDOW } from '@awarenow/profi-ui-core';
import { isPlatformBrowser } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY } from '@libs/captcha/tokens';
import { RequiredReCaptchaSiteKey } from './errors';
import { from, of } from 'rxjs';
import { timeoutWith } from 'rxjs/operators';
import { FeatureFlagService } from '@libs/feature-flags';
import { RECAPTCH_FEATURE_FLAG } from '@libs/captcha/common/consts';

export const reCaptchaScriptProvider = {
  provide: APP_INITIALIZER,
  useFactory:
    (
      scriptLoader: ScriptLoaderService,
      windowService: Window,
      platformId: Object,
      reCaptchaSiteKey: string,
      featureFlagService: FeatureFlagService
    ) =>
    () => {
      if (!featureFlagService.hasFeature(RECAPTCH_FEATURE_FLAG)) {
        return;
      }

      if (!isPlatformBrowser(platformId)) {
        return;
      }

      if (!reCaptchaSiteKey) {
        throw new RequiredReCaptchaSiteKey();
      }

      const hasGrecaptcha = windowService.hasOwnProperty('grecaptcha');

      if (hasGrecaptcha) {
        return;
      }

      return from(
        scriptLoader.load({ src: `//google.com/recaptcha/enterprise.js?render=${reCaptchaSiteKey}`, async: true })
      )
        .pipe(
          /**
           * TODO Prevent issues when script blocked by cookie consent.
           * https://support.cookiebot.com/hc/en-us/community/posts/360006577259-Google-Recaptcha-field-only-loads-if-marketing-cookies-are-enabled-
           */
          timeoutWith(4000, of(null))
        )
        .toPromise();
    },
  deps: [ScriptLoaderService, WINDOW, PLATFORM_ID, RECAPTCHA_V3_SITE_KEY, FeatureFlagService],
  multi: true
};
